import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import type { Survey } from '@vega/common';
import { FlatButton } from 'components/FlatButton';
import { useCallback, useMemo, useState } from 'react';
import { QuestionField } from './QuestionField';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { sitePageScrollMainToAnchor, useAppBarHeight, useAppBarShown } from 'app/layout';
import { getSurveyErrors } from './QuestionStepper';
import { StyleForSurveyCategory, UsersSurveyProgress } from 'app/types';
import { useAppSelector } from 'app/store';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import {
  filterSectionQuestionsAndApplyDefaults,
  findNextNoneEmptySection,
  findPreviousNoneEmptySection,
} from '../helpers';
import { IconButton, LinearProgress, linearProgressClasses, Theme } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { SurveyText } from '@vega/common-react';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { i18next } from '@vega/common';
const t = i18next.t;
const getSectionColours = (theme: Theme) => [
  {
    background: theme.palette.background.default,
    foreground: theme.palette.primary,
    errorBorderColour: theme.palette.error.main,
  },
  { background: theme.palette.seafoam.main, foreground: theme.palette.carnation, errorBorderColour: 'black' },
  { background: theme.palette.carnation.main, foreground: theme.palette.violet, errorBorderColour: 'black' },
];

export const QuestionPages = ({
  survey,
  usersSurveyProgress,
  onChange,
  setStep,
  finishLabel,
  hideProgress,
}: {
  survey: Survey;
  onChange: (data: Record<string, any>) => Promise<void>;
  usersSurveyProgress: UsersSurveyProgress;
  setStep: (step: number) => Promise<void>;
  finishLabel: string;
  hideProgress?: boolean;
}) => {
  const theme = useTheme();
  type Errors = Record<string, string>;
  const [errors, setErrors] = useState<Errors>({});
  const profileData = useAppSelector((state) => state.profile);
  const userProfile = profileData.data?.user;
  const profileError = profileData.error;
  const profileSaving = profileData.saving > 0;

  const progress = usersSurveyProgress[survey.key];
  const section = survey.sections[progress?._step ?? 0];
  const step = progress?._step ?? 0;
  const [nextLoading, setNextLoading] = useState(false);
  const [backLoading, setBackLoading] = useState(false);

  const filteredQuestions = useMemo(
    () => filterSectionQuestionsAndApplyDefaults(section, usersSurveyProgress, survey, userProfile),
    [section, usersSurveyProgress, survey, userProfile],
  );

  const nextSection = useMemo(
    () => findNextNoneEmptySection(survey.sections, section, usersSurveyProgress, survey),
    [survey, section, usersSurveyProgress],
  );
  const prevSection = useMemo(
    () => findPreviousNoneEmptySection(survey.sections, section, usersSurveyProgress, survey),
    [survey, section, usersSurveyProgress],
  );

  const currentSurveyProgress = progress._total > 0 ? progress._completed / progress._total : 0;

  const handleStartClick = useCallback(() => {
    sitePageScrollMainToAnchor(filteredQuestions[0]?.key ?? 'questionPagesFooter');
  }, [filteredQuestions]);

  const handleChange = async (key: string, value: any) => {
    const indexOfQuestionAnswered = filteredQuestions.findIndex((q) => q.key === key);
    const nextKey = filteredQuestions[indexOfQuestionAnswered + 1]?.key ?? 'questionPagesFooter';
    const shouldScroll = filteredQuestions[indexOfQuestionAnswered].type === 'radio';
    if (shouldScroll) {
      sitePageScrollMainToAnchor(nextKey);
    }
    // Re-validate answers which are currently showing an error.
    if (errors?.[key]) {
      const newErrors = getSurveyErrors(
        section,
        survey,
        {
          ...usersSurveyProgress,
          [survey.key]: {
            ...usersSurveyProgress[survey.key],
            [key]: value,
          },
        },
        userProfile,
      );
      setErrors((errors) => ({ ...errors, [key]: newErrors[key] }));
    }
    await onChange({ [key]: value });
  };

  const handleNext = async () => {
    // validate
    const errors = getSurveyErrors(section, survey, usersSurveyProgress, userProfile);
    setErrors(errors);

    const errorKeys = Object.keys(errors);
    if (errorKeys.length === 0) {
      setNextLoading(true);
      if (nextSection === -1) {
        await setStep(survey.sections.length).then(() => {
          setNextLoading(false);
        });
      } else {
        await setStep(nextSection).then(() => {
          setNextLoading(false);
        });
      }
      sitePageScrollMainToAnchor('questionPagesHeader', document.getElementById('progressBar')?.clientHeight);
    } else {
      sitePageScrollMainToAnchor(errorKeys[0], document.getElementById('progressBar')?.clientHeight);
    }
  };

  const handleBack = async () => {
    setBackLoading(true);

    await setStep(prevSection).then(() => {
      setBackLoading(false);
    });
    window.scrollTo({ behavior: 'smooth', top: 0 });
  };

  const sectionColours = getSectionColours(theme);
  const sectionColour = sectionColours[step % sectionColours.length];
  const backgroundColour = sectionColour.background;
  const textColour = theme.palette.getContrastText(backgroundColour);
  const progressContainerStyle = useSliderContainerStyleForSurvey(theme)[survey.category];

  // Make the FlatButton an IconButton
  return (
    <>
      {!hideProgress ? (
        <Box sx={progressContainerStyle} id="progressBar">
          <IconButton
            sx={{ color: 'black' }}
            disabled={step === 0 || nextLoading || backLoading || profileSaving}
            onClick={handleBack}
            aria-label="Back to previous page of survey">
            <KeyboardBackspaceIcon sx={{ fontSize: '2.4rem', fontHeight: '2.4rem' }} />
          </IconButton>
          <Box sx={{ position: 'relative', width: '100%' }}>
            <Typography sx={sliderLabelStyleForSurvey(theme, currentSurveyProgress)[survey.category]}>
              {t('general.progress',{
                completed: progress._completed,
                total: progress._total
              })}
            </Typography>
            <LinearProgress
              variant="determinate"
              value={currentSurveyProgress * 100}
              sx={progressBarStyleForSurvey[survey.category]}
              aria-label="Survey progress"
            />
          </Box>
        </Box>
      ) : null}
      <Box sx={headerContainerStyleForSurvey(theme)[survey.category]} data-scroll-id="questionPagesHeader">
        <Typography variant="h4" sx={headerStyleForSurvey[survey.category]} gutterBottom>
          <SurveyText
            text={section.title}
            survey={survey}
            allSurveysProgress={usersSurveyProgress}
            userProfile={userProfile}
          />
        </Typography>
        <Typography component="div" sx={descriptionStyleForSurvey[survey.category]}>
          <SurveyText
            text={section.description ?? survey.description}
            survey={survey}
            allSurveysProgress={usersSurveyProgress}
            userProfile={userProfile}
          />
        </Typography>
        <Typography
          onClick={handleStartClick}
          sx={{
            fontFamily: 'caveat',
            textTransform: 'uppercase',
            fontWeight: 'normal',
            fontSize: '2rem',
            mt: { xs: 2, md: 8 },
            cursor: 'pointer',
          }}>
          {t('general.scroll-to-start')}
        </Typography>
        <IconButton sx={{ zIndex: 1 }} onClick={handleStartClick} aria-label="Scroll to first unanswered question">
          <KeyboardDoubleArrowDownIcon sx={{ color: 'black', fontSize: '2rem' }} />
        </IconButton>
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="100%"
          height="10rem"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMin slice"
          style={{
            backgroundColor: backgroundColour,
            // This fixes a peculiar sub-pixel rendering bug on safari where the svg would render 1 pixel lower than it's suppposed to
            transform: 'scaleY(110%)',
            zIndex: 0,
          }}>
          <ellipse cx="50" cy="0" rx="55" ry="8" fill={theme.palette.checkInSurvey.main} />
        </svg>
      </Box>
      {!section ? null : (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {filteredQuestions.map((question, index) => {
            const error = errors[question.key];
            return (
              <Box
                key={question.key}
                sx={{
                  backgroundColor: backgroundColour,
                  color: textColour,
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  px: { xs: 2, md: 8, lg: 12, xl: 20 },
                  pb: index === filteredQuestions.length - 1 ? 12 : 0,
                  pt: index === 0 ? 4 : 0,
                }}
                data-scroll-id={question.key}>
                <Box
                  sx={{
                    '--gmci-background-contrast': textColour,
                    '--gmci-controls-main': sectionColour.foreground.main,
                    '--gmci-controls-dark': sectionColour.foreground.dark,
                    '--gmci-controls-light': sectionColour.foreground.light,
                    '--gmci-controls-contrast': sectionColour.foreground.contrastText,
                    width: '100%',
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    px: 2,
                    my: 2,
                    borderStyle: 'solid',
                    borderColor: error ? sectionColour.errorBorderColour : 'transparent',
                    borderWidth: '2px',
                    borderRadius: '1rem',
                    position: 'relative',
                  }}>
                  <QuestionField
                    question={question}
                    data={progress}
                    error={error}
                    onChange={handleChange}
                    survey={survey}
                    userSurveyProgress={usersSurveyProgress}
                    userProfile={userProfile}
                  />
                  <Box
                    sx={{
                      mt: { xs: 8, md: 12 },
                      mb: { xs: 4, md: 8 },
                      width: 'min(80%, 12rem)',
                      height: '4px',
                      borderRadius: '2px',
                      backgroundColor: textColour,
                    }}
                  />
                  <Typography
                    sx={{
                      position: 'absolute',
                      left: 0,
                      bottom: 0,
                      backgroundColor: 'white',
                      color: 'error.main',
                      p: 1,
                      m: 2,
                      width: { xs: 'calc(100% - 16px * 2)', md: 'auto' },
                      borderRadius: '0.6rem',
                      display: error ? 'block' : 'none',
                    }}>
                    {error}
                  </Typography>
                </Box>
              </Box>
            );
          })}
          <Box
            sx={{
              display: 'flex',
              my: 14,
              width: '100%',
              gap: 4,
              flexDirection: { xs: 'column', sm: 'row' },
              px: 2,
              justifyContent: 'center',
            }}
            data-scroll-id="questionPagesFooter">
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 3, alignItems: 'center' }}>
              {section.footer ? (
                <Typography sx={{ fontSize: '1.4rem', mb: 6, textAlign: 'center' }}>
                  {section.footer.description}
                </Typography>
              ) : null}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                  alignItems: 'stretch',
                  width: 'fit-content',
                }}>
                <FlatButton
                  onClick={handleNext}
                  loading={nextLoading || profileSaving}
                  disabled={backLoading || profileError}
                  sx={{
                    fontSize: '1.2rem',
                    backgroundColor: 'black',
                    color: 'white',
                    '&:hover': { backgroundColor: '#555555' },
                  }}>
                  {step === survey.sections.length - 1 || nextSection === -1 ? (
                    finishLabel ?? t('general.submit')
                  ) : (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Typography sx={{ fontSize: '1.2rem' }}>{t('general.save-and-continue')}</Typography>
                      <ArrowBackIcon sx={{ transform: 'rotate(180deg)' }} />
                    </Box>
                  )}
                </FlatButton>
                <FlatButton
                  onClick={handleBack}
                  loading={backLoading || profileSaving}
                  disabled={step === 0 || nextLoading}
                  color="info"
                  sx={{ '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.1)' } }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <ArrowBackIcon />
                    <Typography sx={{ fontSize: '1.2rem' }}>{t('general.go-back')}</Typography>
                  </Box>
                </FlatButton>
              </Box>
            </Box>
          </Box>
          <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={profileError}>
            <Alert severity="error" sx={{ width: '100%' }}>
              There was an error saving your updates! Please try again later.
            </Alert>
          </Snackbar>
        </Box>
      )}
    </>
  );
};

const progressBarStyleForSurvey: StyleForSurveyCategory = {
  gmci: {
    mb: 4,
    height: '0.6rem',
    borderRadius: '2rem',
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#E9E9E9',
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: '2rem',
      backgroundColor: 'black',
    },
  },
  rct: { display: 'none' },
};

const descriptionStyleForSurvey: StyleForSurveyCategory = {
  gmci: {
    maxWidth: 'min(100%, 40rem)',
    textAlign: 'center',
    fontSize: '1.2rem',
    px: 2,
  },
  rct: {},
};

const useSliderContainerStyleForSurvey = (theme: Theme): StyleForSurveyCategory => {
  // For GMCI surveys, we want to have this be fixed to the top of the page, but it should be under the
  // app bar. So we account for the app bar height when its shown
  const appBarShown = useAppBarShown();
  const appBarHeight = useAppBarHeight();
  return {
    gmci: {
      pr: 20,
      pl: { xs: 1, md: 16 },
      pb: 1,
      transition: 'padding 100ms ease-in-out',
      pt: appBarShown ? `calc(16px + ${appBarHeight}px)` : '16px',
      display: 'flex',
      width: '100%',
      gap: 2,
      alignItems: 'center',
      position: 'fixed',
      top: 0,
      background: theme.palette.checkInSurvey.main,
      zIndex: 2,
      borderBottomLeftRadius: '5%',
      borderBottomRightRadius: '5%',
    },
    rct: {},
  };
};

const sliderLabelStyleForSurvey = (theme: Theme, currentSurveyProgress: number): StyleForSurveyCategory => ({
  gmci: {
    position: 'relative',
    left: `${currentSurveyProgress * 100}%`,
    transition: 'left 0.4s linear',
    transform: 'translateX(-0.4rem)',
    width: 'fit-content',
    color: theme.palette.checkInSurvey.main,
    backgroundColor: 'black',
    fontWeight: 'bold',
    py: '0.2rem',
    px: '0.4rem',
    borderRadius: '0.3rem',
    mb: '0.2rem',
    whiteSpace: 'nowrap',
    // Create the triangle pointing down by using transparent and solid borders.
    '&::after': {
      content: '" "',
      position: 'absolute',
      left: '0.1rem',
      bottom: '-0.1rem',
      width: 0,
      height: 0,
      borderLeft: '0.2rem solid transparent',
      borderRight: '0.2rem solid transparent',
      borderTop: '0.2rem solid black',
    },
  },
  rct: {},
});

const headerContainerStyleForSurvey = (theme: Theme): StyleForSurveyCategory => ({
  gmci: {
    backgroundColor: theme.palette.checkInSurvey.main,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // This is a hack to ensure that we don't render content under the progress bar
    mt: 'calc(75px + 16px)',
  },
  rct: { display: 'none' },
});

const headerStyleForSurvey: StyleForSurveyCategory = {
  gmci: {
    px: 2,
    textAlign: 'center',
    fontSize: { xs: '2rem', md: '2.4rem' },
    fontWeight: 'bold',
  },
  rct: {
    width: '100%',
  },
};
