import { useCallback, useEffect, useMemo } from 'react';
import { navigate } from 'gatsby';
import { Typography, Box, Grid, Container } from '@mui/material';
import { Section } from 'components';
import { startSurvey, useAppDispatch, useAppSelector } from 'app/store';
import { SitePage } from 'app/layout';
import { ensureAuth } from 'app/helpers';
import { useLocation } from '@reach/router';
import ButtonBase from '@mui/material/ButtonBase';
import { i18next } from '@vega/common';
import { scrollToElement } from 'app/helpers/scrolling';
import { getMachineState, getSurveyIdFromStateMeta, Survey, SurveyProgress, surveys } from '@vega/common';
import { SurveyCard } from 'app/survey/SurveyCard';
import { useAsyncState } from 'app/hooks';
import { ColourBlock, IconComment } from '@vega/common-react';
import arrow from '../../app/landing/imgs/arrow.png';
import { Collaborators } from 'components/Collaborators';
import moment from 'moment';
import 'moment/locale/zh-cn';

const t = i18next.t;
function DashboardPage() {
  const location = useLocation();
  const dispatch = useAppDispatch();

  // TODO: Render some sort of loading state
  const [_startSurveyThunkState, successfullyStartedSurvey, failedToStartSurvey, startSurveyPending] = useAsyncState();

  // Since the dashboard is rendered on the client, anchors in the URL
  // don't work natively. Here, we watch the current URL for an anchor
  // and scroll there if there is one
  useEffect(() => {
    if (location.hash) {
      scrollToElement(document.getElementById(window.location.hash.substring(1)));
    }
  }, [location]);

  const { surveyProgress, userJourneyData } = useAppSelector((state) => state.profile.data!);
  const currentSurveyId = getSurveyIdFromStateMeta(userJourneyData.currentState);
  const final = useMemo(() => {
    const state = getMachineState(userJourneyData.currentState);
    return 'type' in state && state.type === 'final';
  }, [userJourneyData.currentState]);

  const pastSurveyKeys = Object.keys(surveyProgress).filter((surveyKey) => surveyKey !== currentSurveyId);

  const pastSurveys = useMemo(() => {
    return pastSurveyKeys
      .map((pastSurveyKey) => {
        return surveys.find((survey) => survey.key === pastSurveyKey);
      })
      .filter((survey) => survey?.category === 'gmci') as Survey[];
  }, [pastSurveyKeys]);

  const currentSurvey = useMemo(() => {
    if (!currentSurveyId) {
      return undefined;
    }
    const currentSurveyProgress = surveyProgress[currentSurveyId] ?? { _total: 0, _completed: 0 };
    if (!currentSurveyProgress) {
      return undefined;
    }
    const currentSurveyData = surveys.find((survey) => survey.key === currentSurveyId);
    if (!currentSurveyData) {
      return undefined;
    }

    return { ...currentSurveyData, ...currentSurveyProgress };
  }, [surveyProgress, currentSurveyId]);

  const currentSurveyProgress =
    currentSurvey && currentSurvey._total > 0 ? currentSurvey._completed / currentSurvey._total : undefined;

  const onCurrentSurveyClick = () => {
    if (currentSurveyProgress === undefined) {
      startSurveyPending();
      dispatch(startSurvey()).then(() => {
        successfullyStartedSurvey();
        navigate(`/surveys/${currentSurveyId}`);
      }, failedToStartSurvey);
      return;
    }
    navigate(`/surveys/${currentSurveyId}`);
  };

  const isSurveyComplete = useCallback(
    (surveyKey: string) =>
      surveyProgress[surveyKey]?._total && surveyProgress[surveyKey]._completed === surveyProgress[surveyKey]._total,
    [surveyProgress],
  );

  const onPastSurveyClick = useCallback(
    (surveyKey: string) => {
      if (isSurveyComplete(surveyKey)) {
        navigate(`/surveys/${surveyKey}`);
      }
    },
    [isSurveyComplete],
  );

  return (
    <SitePage>
      <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <WelcomeBlock currentSurvey={currentSurvey} onContinueClick={onCurrentSurveyClick} />
      </Container>
      <Box width="100%">
        <ColourBlock backgroundColor="violet.main">
          <Container sx={{ pb: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Section sx={{ width: '100%' }} titleColour="white" title={t('dashboard-page.ongoing-survey')}>
              <Grid container spacing={2} sx={{ mt: 2 }}>
                {currentSurvey ? (
                  <Grid item xs={12} sm={8} md={4}>
                    <SurveyCard
                      id={currentSurvey.key}
                      src={currentSurvey.cover}
                      tint={currentSurvey.tint}
                      title={currentSurvey.title}
                      extraStyle={currentSurvey.extraStyle}
                      progress={currentSurveyProgress ?? 0}
                      onClick={onCurrentSurveyClick}
                      transitionDelay={1}
                    />
                  </Grid>
                ) : !final ? (
                  <Grid item>
                    <Typography sx={{ color: 'white' }}>
                      {t('dashboard-page.upcoming-survey.no-survey')}
                    </Typography>
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <Typography sx={{ color: 'white', textAlign: 'center' }}>
                      {t('dashboard-page.upcoming-survey.no-survey')}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Section>
          </Container>
        </ColourBlock>
        <Container sx={{ pb: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {pastSurveys.length === 0 ? null : (
            <Section sx={{ width: '100%' }} title={t('dashboard-page.previous-check-ins.title')}>
              <Typography>{t('dashboard-page.previous-check-ins.description')}</Typography>
              <Grid container spacing={2} sx={{ mt: 2 }}>
                {pastSurveys.map((pastSurvey) => {
                  const startedDate = surveyProgress[pastSurvey.key]?._startedTimestamp?.toDate();
                  const startedMoment = startedDate ? moment(startedDate).locale(i18next.language).format('LL') : undefined;
                  return (
                    <Grid key={pastSurvey.key} item xs={12} sm={8} md={4}>
                      <SurveyCard
                        id={pastSurvey.key}
                        src={pastSurvey.cover}
                        tint={pastSurvey.tint}
                        title={pastSurvey.title}
                        subtitle={`${startedMoment ?? ''}`}
                        extraStyle={pastSurvey.extraStyle}
                        progress={isSurveyComplete(pastSurvey.key) ? 1 : null}
                        onClick={() => onPastSurveyClick(pastSurvey.key)}
                        transitionDelay={1}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Section>
          )}
        </Container>
      </Box>
      <Container sx={{ pb: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography sx={{ mb: 5, fontSize: '1.5rem', textAlign: 'center', fontWeight: 800, maxWidth: '51rem', mt: 12 }}>
          {t('landing-page.contributors-description')}
        </Typography>
        <Collaborators />
      </Container>
    </SitePage>
  );
}

const WelcomeBlock = (props: {
  /**
   * Whether the user currently has a survey to complete
   */
  currentSurvey?: SurveyProgress;
  onContinueClick: () => void;
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        my: 4,
        textAlign: 'center',
        maxWidth: '51rem',
      }}>
      <Typography fontWeight="bold" sx={{ fontSize: { xs: '1.5rem', sm: '2.5rem' } }}>
        {t('dashboard-page.welcome')}
      </Typography>
      <Typography sx={{ mt: 3, mb: 4 }}>{t('landing-page.description')}</Typography>
      {props.currentSurvey ? (
        <>
          <ButtonBase
            onClick={props.onContinueClick}
            sx={{
              borderRadius: '0.7rem',
              textTransform: 'none',
              fontWeight: 'bold',
              color: 'white',
              py: 2,
              px: 12,
              fontSize: '1.25rem',
              backgroundColor: 'black',
              '&:hover': { backgroundColor: '#555555' },
            }}>
            {props.currentSurvey._completed ? t('general.continue') : t('general.start')}
          </ButtonBase>
          <IconComment sx={{ mt: 2 }} img={arrow}>
            {t('landing-page.start-button.description')}
          </IconComment>
        </>
      ) : null}
    </Box>
  );
};

export default ensureAuth(DashboardPage);
