import { navigate } from 'gatsby';
import { isMobile } from 'react-device-detect';
import { Box, Typography } from '@mui/material';
import { Feedback } from 'app/feedback';
import { addDoc, doc, getDoc, serverTimestamp, Timestamp, updateDoc } from 'firebase/firestore';
import { useCallback, useEffect } from 'react';
import { Survey, UserDetails } from '@vega/common';
import { FlatButton } from 'components/FlatButton';

import { getUid } from '../store/thunks/helper';
import {
  getSurveyProgressCollection,
  getSurveyRecommendationCollection,
  getUserFeedbackCollection,
} from '../../firebaseApp';

export type SurveyFeedbackProps = {
  survey: Survey;
  user: UserDetails;
};

export function SurveyFeedback(props: SurveyFeedbackProps) {
  const { survey, user } = props;

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const logRecommendationAction = useCallback(
    async (recommendation: string, navigation: boolean, event: 'enter' | 'leave') => {
      const collection = getSurveyRecommendationCollection(getUid(), survey.key, navigation);
      await addDoc(collection, {
        recommendation,
        timestamp: serverTimestamp(),
        event,
      });
    },
    [survey.key],
  );

  const saveUserFeedback = useCallback(
    async (feedback: string) => {
      const collection = getUserFeedbackCollection(getUid(), survey.key);
      await addDoc(collection, {
        feedback,
        timestamp: serverTimestamp(),
      });
    },
    [survey.key],
  );

  const userId = getUid();

  // Detect if the user prints the feedback page, and record the timestamps when they do.
  useEffect(() => {
    const handler = async () => {
      const progressCollection = getSurveyProgressCollection(userId);
      const surveyProgressDoc = doc(progressCollection, survey.key);
      const progressSnapshot = await getDoc(surveyProgressDoc);
      const progress = progressSnapshot.data();
      // serverTimestamp() is not currently supported inside arrays :(
      const _feedbackDownloaded = [...(progress?._feedbackDownloaded ?? []), Timestamp.fromDate(new Date())];
      await updateDoc(surveyProgressDoc, { _feedbackDownloaded });
    };
    addEventListener('afterprint', handler);
    return () => {
      removeEventListener('afterprint', handler);
    };
  }, [userId, survey.key]);

  const handlePrintClick = useCallback(async () => {
    // chrome and safari
    if (!document.execCommand?.('print', false, undefined)) {
      // Chrome and firefox
      window.print();
    }
  }, []);

  return (
    <Box pb={4}>
      <Feedback
        surveyKey={survey.key}
        user={user}
        logRecommendationAction={logRecommendationAction}
        saveUserFeedback={saveUserFeedback}
      />

      <Box mt={2} p={2} sx={{ gap: 1 }} display="flex" justifyContent="end">
        {isMobile ? (
          <Typography>Visit this page on a computer to print feedback</Typography>
        ) : (
          <FlatButton color="secondary" onClick={handlePrintClick}>
            Print
          </FlatButton>
        )}
        <FlatButton color="primary" onClick={() => navigate('/dashboard')}>
          Back To Dashboard
        </FlatButton>
      </Box>
    </Box>
  );
}
