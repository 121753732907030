import {
  getAuth,
  signOut,
  sendPasswordResetEmail,
  UserInfo,
  updatePassword,
  updateProfile,
  signInWithCustomToken,
} from 'firebase/auth';
import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const resetPassword = createAsyncThunk('auth/resetPassword', async (email: string): Promise<void> => {
  const auth = getAuth();
  const analytics = getAnalytics();
  await sendPasswordResetEmail(auth, email);
  logEvent(analytics, 'reset_password');
});

export const signout = createAsyncThunk('auth/signout', async (): Promise<void> => {
  const auth = getAuth();
  await signOut(auth);
});

export const changePassword = createAsyncThunk('auth/changePassword', async (newPassword: string): Promise<void> => {
  const auth = getAuth();
  await updatePassword(auth.currentUser!, newPassword);
});

export const signin = createAsyncThunk('auth/signin', async (token: string): Promise<void> => {
  await signInWithCustomToken(getAuth(), token).then(() => {

    const auth = getAuth();
    const analytics = getAnalytics()
    const user = auth.currentUser;
    if (user) {
      setUserProperties(analytics, { userId: user.uid, email: user.email });
      logEvent(analytics, 'login',{ userId: user.uid, email: user.email });
    }
  });
});

export const updatePhotoURL = createAsyncThunk('auth/updatePhoto', async (photoURL: string): Promise<
  Partial<UserInfo>
> => {
  const auth = getAuth();
  await updateProfile(auth.currentUser!, { photoURL });
  return { photoURL };
});
