import { Paper, Stack, TextField, Typography, Box, Alert, IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Logo } from 'images/Logo';
import { WaitButton } from 'components';
import { Form, mustBeEmail } from 'components/form';
import { AuthPage } from 'app/layout';
import { useAsyncState } from 'app/hooks';
import { useCallback } from 'react';
import type { Validate } from 'components/form';
import { sendSignInLink } from 'firebaseApp';
import { i18next } from '@vega/common';
const credentialValidator: Record<string, Validate> = {
  email: mustBeEmail(),
};

type Credential = {
  email: string;
};

export const signIn = async (credential: Credential): Promise<string> => {
  const result = await sendSignInLink(credential);
  return result.data;
};

export default function Signin() {
  const [signInState, success, failure, waiting] = useAsyncState<string>();

  const handleSubmit = useCallback((data: Partial<Credential>) => {
    if (data.email !== undefined) {
      signIn(data as Credential).then(success, failure);
      waiting();
    }
  }, []);

  const errorText = (signInState.status !== 'failure') ? null
    : signInState.error.message ?? signInState.error.code ?? 'Sign-in failed';

  const onCopyError = useCallback(() => (
    navigator.clipboard.writeText(errorText)
  ), [errorText]);

  return (
    <AuthPage>
      <Logo />
      <Paper sx={{ my: 4, px: 4, py: 6, width: { xs: '90%', sm: 500 } }}>
        <Box sx={{ textAlign: 'center', mb: 3 }}>
          <Typography variant="h5">{i18next.t('general.login')}</Typography>
          {signInState.status !== 'fulfilled' ? (
            <Typography color="textSecondary" paragraph>
              {i18next.t('general.email-signin-subtitle')}
            </Typography>
          ) : null}
          {signInState.status === 'failure' && (
            <Alert severity="error" action={ <IconButton onClick={onCopyError}><ContentCopyIcon /></IconButton> }>
              {errorText}
            </Alert>
          )}
        </Box>

        {signInState.status !== 'fulfilled' ? (
          <Form<Partial<Credential>> initialData={{}} onSubmit={handleSubmit} validators={credentialValidator}>
            {(state, updateField) => {
              return (
                <Stack spacing={3}>
                  <TextField
                    id="email"
                    value={state.data.email || ''}
                    onChange={(e) => updateField('email', e.target.value)}
                    error={Boolean(state.errors['email'])}
                    helperText={state.errors['email']}
                    required
                    fullWidth
                    label={i18next.t('general.email-placeholder')}
                    autoComplete="username"
                  />
                  <WaitButton id="submit" type="submit" variant="contained" wait={signInState.status === 'pending'}>
                    {i18next.t('general.email-signin-button')}
                  </WaitButton>
                </Stack>
              );
            }}
          </Form>
        ) : null}
        {
          signInState.status !== 'fulfilled' ? null
            : signInState.result ?  (
              <Box sx={{ textAlign: 'center', mb: 3 }}>
                <Typography color="textSecondary" paragraph>
                  {/* {signInState.result} */}
                  {i18next.t('general.sign-feedback.description')}
                </Typography>
                <Typography color="textSecondary" paragraph>
                  {i18next.t('general.sign-feedback.snippet-1')} <a href="/">{i18next
                    .t('general.sign-feedback.link-placeholder')}</a> {i18next.t('general.sign-feedback.snippet-2')}
               </Typography>
              </Box>
            ) : null
        }
      </Paper>
    </AuthPage>
  );
}
